import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ProductsTable from "../components/productsTable"

export default function Products(props) {
  const products = props.data.allMongodbDevelopProducts.edges;
  return (
    <Layout>
      <div className="products full-page-module">
        <ProductsTable products={products} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    allMongodbDevelopProducts {
      edges {
        node {
          mongodb_id
          name
          scrapeUrl
          affiliateUrl
          retailer
          roles
          tags
          experiment
          priority
        }
      }
    }
  }
`
