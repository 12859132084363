import React from "react"

const ProductRow = ({ id, url, name }) => {
  return (
    <div className="product-row" key={id}>
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <span className="link-spanner"></span>
      </a>
      {name}
    </div>
  );
}

export default function ProductsTable({ products }) {
  return (
    <div className="product-table">
      {products.map(product =>
        <ProductRow 
          id={product.node.mongodb_id}
          url={product.node.affiliateUrl || product.node.scrapeUrl}
          name={product.node.name} />
        )}
    </div>
  );
}
